import App from 'next/app'
import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { QueryClient, QueryClientProvider, HydrationBoundary, QueryCache } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import smoothscroll from 'smoothscroll-polyfill';
import { parseCookies } from 'nookies'
import { ThemeProvider, StyleSheetManager } from 'styled-components'
import { theme } from "../theme/theme";
import { GlobalStyle } from "../theme/global-styles";
import { TrackPageViewManager } from "../components/TrackPageViewManager";
import "react-datepicker/dist/react-datepicker.css";
import 'swiper/swiper.min.css';
import 'swiper/css/effect-fade';
import 'simplebar-react/dist/simplebar.min.css';
import { ErrorBoundary } from '../components/ErrorBoundary'
import LocalStore, { LocalStorageKeys } from '../localstorage/LocalStorage'
import { UrlParser } from 'authory-api-types/dist/types/source/parsing'
import { CookieContext } from '../context/cookieContext'
import { Toaster } from 'react-hot-toast';
import { toastConfig } from '../utils/toastConfig'
import { Generic403, Generic404, Generic500 } from '../pagelayouts/generic-error-page'
import { XRequestHostHeader } from '../types/headers'
import { DomainContext, HostContext } from '../context/domainContext'
import { AddElementSkeletonTracker } from '../context/addElementSkeletonContext'
import { ModalEndOfTrial } from '../components/ModalEndOfTrial'
import { APPLICATION_ROUTES } from '../types/routes'
import { logAxiosError } from '../logging/logSentryError'
import { AxiosError } from 'axios'
import { Errors } from '../types/api'
import { NotificationHandler, V3GenericErrorHandler } from '../api/error-handler'
import isPropValid from '@emotion/is-prop-valid'
import { BeeHiivScript } from '../components/BeeHiivScript'

// This implements the default behavior from styled-components v5 - we can only remove this once we move all component to use transient props
function shouldForwardProp(propName: string, target: any) {
    if (typeof target === "string") {
        // For HTML elements, forward the prop if it is a valid HTML attribute
        return isPropValid(propName);
    }
    // For other elements, forward all props
    return true;
}

const isProduction = process.env.NODE_ENV === "production";
export default function MyApp({ Component, pageProps }: any) {

    const router = useRouter();
    const [domain] = useState(pageProps.domain);

    const statusCode = pageProps.statusCode;
    const isAppRoute = router.route.startsWith("/app");
    const isBilling = router.route === APPLICATION_ROUTES.SETTINGS_BILLING;
    const isNotifications = router.route === APPLICATION_ROUTES.SETTINGS_NOTIFICATIONS;

    const [queryClient] = React.useState(() => new QueryClient({
        defaultOptions: {
            queries: {
                retry: false,
                throwOnError: true,
                staleTime: Infinity
            },
        },
        queryCache: new QueryCache({
            onError: (error, query) => {

                if (query?.meta?.logNotFound && (error as AxiosError)?.response?.status !== Errors.NotFound) {
                    logAxiosError(error as AxiosError);
                }

                if (query?.meta?.useGenericErrorHandler && query?.meta?.toastErrorCallback) {
                    V3GenericErrorHandler(query?.meta?.toastErrorCallback as NotificationHandler)(error as AxiosError);
                }
            },
        }),
    }))


    useEffect(() => {
        // kick off the polyfill!
        smoothscroll.polyfill();
    }, []);

    useEffect(() => {
        if (router.pathname.endsWith("/embed")) return;

        if ('serviceWorker' in navigator) {
            try {
                navigator.serviceWorker
                    .register('/authory_static_files/remove_assets_cookies.js')
                    .then((registration) => {
                        console.log(
                            'Service Worker registration successful with scope: ',
                            registration.scope
                        );
                    })
                    .catch((err) => console.log('Service Worker registration failed:', err))

                // Cleaning up old service worker path
                navigator.serviceWorker.getRegistrations()
                    .then(function (registrations) {
                        for (let registration of registrations) {
                            const isRemoveCookiesScript = registration.active?.scriptURL.endsWith("remove_assets_cookies.js");
                            const isOldPath = !registration.scope.includes("authory_static_files");
                            if (isRemoveCookiesScript && isOldPath) registration.unregister();
                        }
                    });
            } catch { }
        }
    }, []);

    useEffect(() => {
        // Get UTM props from query
        let query = router.query

        if (query.fbclid && typeof query.fbclid === "string") {
            new LocalStore(LocalStorageKeys.tracking_fbclid).set(query.fbclid);
        }

        if (query.utm_source && typeof query.utm_source === "string") {
            new LocalStore(LocalStorageKeys.tracking_utm_source).set(query.utm_source);
        }

        if (query.utm_medium && typeof query.utm_medium === "string") {
            new LocalStore(LocalStorageKeys.tracking_utm_medium).set(query.utm_medium);
        }

        if (query.utm_campaign && typeof query.utm_campaign === "string") {
            new LocalStore(LocalStorageKeys.tracking_utm_campaign).set(query.utm_campaign);
        }

        if (query.utm_term && typeof query.utm_term === "string") {
            new LocalStore(LocalStorageKeys.tracking_utm_term).set(query.utm_term);
        }

        if (query.utm_content && typeof query.utm_content === "string") {
            new LocalStore(LocalStorageKeys.tracking_utm_content).set(query.utm_content);
        }

        if (document.referrer) {

            let domain;

            try {
                domain = UrlParser.extractDomain(document.referrer);
            } catch {
                domain = "";
            }

            // Save document referrer.
            const reservedHostnames = ["authory.com", "www.authory.com", "localhost", "staging.authory.com", null];

            if (!reservedHostnames.includes(domain)) {
                new LocalStore(LocalStorageKeys.tracking_referrer).set(document.referrer);
            }
        }

    }, [router.query]);

    return (<>
        <StyleSheetManager shouldForwardProp={shouldForwardProp}>
            <GlobalStyle />
            <ThemeProvider theme={theme}>
                <ErrorBoundary>
                    <QueryClientProvider client={queryClient}>
                        <CookieContext.Provider value={pageProps.cookieValue}>
                            <DomainContext.Provider value={domain}>
                                <AddElementSkeletonTracker>
                                    <HostContext.Provider value={pageProps.host}>
                                        <HydrationBoundary state={pageProps.dehydratedState}>
                                            {isAppRoute && !isBilling && !isNotifications && <ModalEndOfTrial />}
                                            <Toaster
                                                position="bottom-center"
                                                toastOptions={toastConfig}
                                                containerClassName={`ToasterAuthoryContainer`}
                                            />
                                            <TrackPageViewManager />
                                            <BeeHiivScript />
                                            {statusCode === 404 ? <Generic404 /> : null}
                                            {statusCode === 500 ? <Generic500 /> : null}
                                            {statusCode === 403 ? <Generic403 target={pageProps?.unavailableResourceType} /> : null}
                                            {statusCode === 200 || !statusCode ? <Component {...pageProps} /> : null}
                                        </HydrationBoundary>
                                    </HostContext.Provider>
                                </AddElementSkeletonTracker>
                            </DomainContext.Provider>
                        </CookieContext.Provider>
                        {!isProduction && <ReactQueryDevtools initialIsOpen={false} />}
                    </QueryClientProvider>
                </ErrorBoundary>
            </ThemeProvider>
        </StyleSheetManager>
    </>)
}

MyApp.getInitialProps = async (appContext: any) => {
    // calls page's `getInitialProps` and fills `appProps.pageProps`
    const appProps = await App.getInitialProps(appContext);
    const cookies = parseCookies(appContext.ctx);

    return {
        pageProps: {
            ...appProps.pageProps,
            cookieValue: cookies.token,
            domain: appContext.ctx.res?.getHeader(XRequestHostHeader),
            host: appContext.ctx.req ? appContext.ctx.req?.headers["host"] : window?.location?.host
        }
    }
}