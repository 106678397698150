import React from "react"
import { APPLICATION_ROUTES } from "../../types/routes"
import { Body, H4 } from "../Typography"
import { VerticalSpacing } from "../VerticalSpacing"
import { FooterLinkColumn, FooterMainLink, ImprintLink, ImprintText, VerticalSpacingWrapper, MarketingFooterContent, MarketingFooterWrapper, ColumnsLinkWrapper } from "./MarketingFooter.styles"
import { FTMARKETINGPAGES } from "../../utils/featuresMarketingPage";
import { UCMARKETINGPAGES } from "../../utils/useCasesMarketingPages";
import { MOREMARKETINGPAGES } from "../../utils/moreMarketingPages";
import { OptionalNextLink } from "../ReusableStyledComponents/OptionalNextLink";

const footerLinks = [
    { href: "/blog/writing-portfolio/", label: "Writing Portfolio" },
    { href: "/blog/journalism-portfolio/", label: "Journalism Portfolio" },
    { href: "/blog/writing-backup-save-all-your-writing-forever/", label: "Writing Backup" },
    { href: "/blog/content-marketing-portfolio/", label: "Content Marketing Portfolio" },
    { href: "/blog/social-media-portfolio/", label: "Social Media Portfolio" },
    { href: "/blog/best-portfolio-builder/", label: "Best Portfolio Builders" },
    { href: "/blog/how-to-create-a-pdf-portfolio-plus-excellent-pdf-portfolio-examples/", label: "Portfolio PDF Examples" },
    { href: "/blog/auto-updating-twitter-archive-with-advanced-search/", label: "Twitter Archive" },
    { href: APPLICATION_ROUTES.PORTFOLIO_CREATOR, label: "Portfolio Creator" },
]

type MarketingFooterProps = {
    backgroundColor?: string,
    useNextRouter?: boolean
}

export const MarketingFooter = ({ backgroundColor, useNextRouter = true }: MarketingFooterProps) => <>
    <MarketingFooterWrapper backgroundColor={backgroundColor} >
        <MarketingFooterContent>
            <VerticalSpacingWrapper>
                <div>
                    <img src="/authory_static_files/images/icons/authory_footer_logo.svg" alt="authory footer logo" />
                </div>
                <VerticalSpacing bottom={4} top={20}>
                    <ImprintText>
                        <span>
                            <OptionalNextLink href={APPLICATION_ROUTES.DIRECTORY} useNextRouter={useNextRouter}>
                                <ImprintLink>
                                    Directory
                                </ImprintLink>
                            </OptionalNextLink>
                        </span>
                        <br />
                        <span>
                            <OptionalNextLink href={APPLICATION_ROUTES.IMPRINT} useNextRouter={useNextRouter}>
                                <ImprintLink >
                                    Imprint
                                </ImprintLink>
                            </OptionalNextLink>
                        </span>
                        <br />
                        <span>
                            Copyright 2025
                        </span>
                        <br />
                        All rights reserved.
                    </ImprintText>
                </VerticalSpacing>
            </VerticalSpacingWrapper>
            <ColumnsLinkWrapper>
                <div>
                    <VerticalSpacing bottom={24}>
                        <H4>Features</H4>
                    </VerticalSpacing>
                    <Body>
                        <FooterLinkColumn>
                            {
                                FTMARKETINGPAGES.map(feature => <li key={feature.route}>
                                    <OptionalNextLink href={feature.route} useNextRouter={useNextRouter}>
                                        <FooterMainLink>{feature.primaryText}</FooterMainLink>
                                    </OptionalNextLink>
                                </li>)
                            }
                        </FooterLinkColumn>
                    </Body>
                </div>
                <div>
                    <VerticalSpacing bottom={24}>
                        <H4>Use Cases</H4>
                    </VerticalSpacing>
                    <Body>
                        <FooterLinkColumn>
                            {
                                UCMARKETINGPAGES.map(uc => <li key={uc.route}>
                                    <OptionalNextLink href={uc.route} useNextRouter={useNextRouter}>
                                        <FooterMainLink>{uc.primaryText}</FooterMainLink>
                                    </OptionalNextLink>
                                </li>)
                            }
                        </FooterLinkColumn>
                    </Body>
                </div>
                <div>
                    <VerticalSpacing bottom={24}>
                        <H4>More</H4>
                    </VerticalSpacing>
                    <Body>
                        <FooterLinkColumn>
                            {
                                MOREMARKETINGPAGES.map(item => <li key={item.href}>
                                    {
                                        (item.href === APPLICATION_ROUTES.BLOG || item.href === APPLICATION_ROUTES.EXAMPLES) ? <a href={item.href} style={{ textDecoration: "none" }}>
                                            <FooterMainLink as="div">{item.primaryText}</FooterMainLink>
                                        </a> : <OptionalNextLink href={item.href} useNextRouter={useNextRouter}>
                                            <FooterMainLink>{item.primaryText}</FooterMainLink>
                                        </OptionalNextLink>
                                    }
                                </li>)
                            }
                        </FooterLinkColumn>
                    </Body>
                </div>
            </ColumnsLinkWrapper>
            <div>
                <VerticalSpacing bottom={24}>
                    <H4>Resources</H4>
                </VerticalSpacing>
                <Body>
                    <FooterLinkColumn>
                        {
                            footerLinks.map(({ label, href }) => <li key={label}>
                                <OptionalNextLink href={href} target="_blank" useNextRouter={false}>
                                    <FooterMainLink>{label}</FooterMainLink>
                                </OptionalNextLink>
                            </li>)
                        }
                    </FooterLinkColumn>
                </Body>
            </div>
        </MarketingFooterContent >
    </MarketingFooterWrapper >
</>